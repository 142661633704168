import { isClient } from "@vueuse/core";
import type { CSSProperties } from "vue";
import { camelize } from "@vue/shared";

const getStyle = (element: HTMLElement, styleName: keyof CSSProperties): string => {
  if (!isClient || !element || !styleName) return "";

  let key = camelize(styleName);
  if (key === "float") key = "cssFloat";
  try {
    const style = (element.style as CSSStyleDeclaration)[key as keyof CSSStyleDeclaration] as string;
    if (style) return style;
    const computed = document.defaultView?.getComputedStyle(element, "") as CSSStyleDeclaration;
    return computed ? (computed[key as keyof CSSStyleDeclaration] as string) : "";
  } catch {
    return element.style[key as keyof CSSStyleDeclaration] as string;
  }
};

export const isScroll = (el: HTMLElement, isVertical?: boolean): boolean => {
  if (!isClient) return false;

  const key = (
    {
      undefined: "overflow",
      true: "overflow-y",
      false: "overflow-x",
    } as const
  )[String(isVertical)]!;
  const overflow = getStyle(el, key);
  return ["scroll", "auto", "overlay"].some((s) => overflow.includes(s));
};

export const getScrollContainer = (el: HTMLElement, isVertical?: boolean): Window | HTMLElement | undefined => {
  if (!isClient) return;

  let parent: HTMLElement = el;
  while (parent) {
    if ([window, document, document.documentElement].includes(parent)) return window;

    if (isScroll(parent, isVertical)) return parent;

    parent = parent.parentNode as HTMLElement;
  }

  return parent;
};

export const getOffsetTop = (el: HTMLElement) => {
  let offset = 0;
  let parent = el;

  while (parent) {
    offset += parent.offsetTop;
    parent = parent.offsetParent as HTMLElement;
  }

  return offset;
};

export const getOffsetTopDistance = (el: HTMLElement, containerEl: HTMLElement) => {
  return Math.abs(getOffsetTop(el) - getOffsetTop(containerEl));
};
