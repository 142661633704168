import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAuthConfirmEmail, LazyAuthFooter, LazyAuthForgotPassword, LazyAuthForm, LazyAuthImageSection, LazyAuthInput, LazyAuthLayout, LazyAuthModal, LazyAuthRedirect, LazyAuthToggle, LazySignInForm, LazySignUpForm, LazyThirdPartyAuthButtons, LazySettingsEditProfileModal, LazySettingsFeedbackModal, LazySettingsPasswordEditModal, LazySetupAgeSelection, LazySetupModalContainer, LazySetupNameAndPronoun, LazySetupUnderAge, LazySetupUnderAgeWithChangeAge, LazyChooseCharacter, LazyImageView, LazySetPrompt, LazyCharacterSelect, LazyCharacterAttrButtonSelect, LazyCharacterAttrCard, LazyCharacterAttrCardSelect, LazyCharacterAttrSummary, LazyCharacterAttrSummaryItem, LazyCharacterCard, LazyCharacterCard2, LazyButton, LazyEmpty, LazyImageViewer, LazyListSelect, LazyLoadingIcon, LazyMessageBox, LazyModal, LazySearchInput, LazySimpleButton, LazySimpleDiv, LazySlider, LazySteps, LazySwiperImgs, LazyTag, LazyUListSelectItem, LazyUListSelect, LazyAvatar, LazyAvatarFallback, LazyAvatarImage, LazyInput, LazyPopover, LazyPopoverContent, LazyPopoverTrigger, LazySkeleton, LazySonner, LazyTooltip, LazyTooltipContent, LazyTooltipProvider, LazyTooltipTrigger, LazyUButton, LazyUIcon, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["AuthConfirmEmail", LazyAuthConfirmEmail],
["AuthFooter", LazyAuthFooter],
["AuthForgotPassword", LazyAuthForgotPassword],
["AuthForm", LazyAuthForm],
["AuthImageSection", LazyAuthImageSection],
["AuthInput", LazyAuthInput],
["AuthLayout", LazyAuthLayout],
["AuthModal", LazyAuthModal],
["AuthRedirect", LazyAuthRedirect],
["AuthToggle", LazyAuthToggle],
["SignInForm", LazySignInForm],
["SignUpForm", LazySignUpForm],
["ThirdPartyAuthButtons", LazyThirdPartyAuthButtons],
["SettingsEditProfileModal", LazySettingsEditProfileModal],
["SettingsFeedbackModal", LazySettingsFeedbackModal],
["SettingsPasswordEditModal", LazySettingsPasswordEditModal],
["SetupAgeSelection", LazySetupAgeSelection],
["SetupModalContainer", LazySetupModalContainer],
["SetupNameAndPronoun", LazySetupNameAndPronoun],
["SetupUnderAge", LazySetupUnderAge],
["SetupUnderAgeWithChangeAge", LazySetupUnderAgeWithChangeAge],
["ChooseCharacter", LazyChooseCharacter],
["ImageView", LazyImageView],
["SetPrompt", LazySetPrompt],
["CharacterSelect", LazyCharacterSelect],
["CharacterAttrButtonSelect", LazyCharacterAttrButtonSelect],
["CharacterAttrCard", LazyCharacterAttrCard],
["CharacterAttrCardSelect", LazyCharacterAttrCardSelect],
["CharacterAttrSummary", LazyCharacterAttrSummary],
["CharacterAttrSummaryItem", LazyCharacterAttrSummaryItem],
["CharacterCard", LazyCharacterCard],
["CharacterCard2", LazyCharacterCard2],
["Button", LazyButton],
["Empty", LazyEmpty],
["ImageViewer", LazyImageViewer],
["ListSelect", LazyListSelect],
["LoadingIcon", LazyLoadingIcon],
["MessageBox", LazyMessageBox],
["Modal", LazyModal],
["SearchInput", LazySearchInput],
["SimpleButton", LazySimpleButton],
["SimpleDiv", LazySimpleDiv],
["Slider", LazySlider],
["Steps", LazySteps],
["SwiperImgs", LazySwiperImgs],
["Tag", LazyTag],
["UListSelectItem", LazyUListSelectItem],
["UListSelect", LazyUListSelect],
["Avatar", LazyAvatar],
["AvatarFallback", LazyAvatarFallback],
["AvatarImage", LazyAvatarImage],
["Input", LazyInput],
["Popover", LazyPopover],
["PopoverContent", LazyPopoverContent],
["PopoverTrigger", LazyPopoverTrigger],
["Skeleton", LazySkeleton],
["Sonner", LazySonner],
["Tooltip", LazyTooltip],
["TooltipContent", LazyTooltipContent],
["TooltipProvider", LazyTooltipProvider],
["TooltipTrigger", LazyTooltipTrigger],
["UButton", LazyUButton],
["UIcon", LazyUIcon],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
