import type { FetchError, FetchResponse, SearchParameters } from "ofetch";
import type { AsyncData, UseFetchOptions } from "#app";
import type { KeysOf, PickFrom } from "#app/composables/asyncData";
import type { ResOptions } from "@/types/http";

const { handleStatusCode } = useStatusCodeHandler();

type UrlType = string | Request | Ref<string | Request> | (() => string | Request);

type HttpOption<T> = UseFetchOptions<ResOptions<T>, T, KeysOf<T>>;

function handleError<T>(response: FetchResponse<ResOptions<T>> & FetchResponse<ResponseType>) {
  if (response?._data?.errNo) handleStatusCode(response._data.errNo);
}

function fetch<T>(url: UrlType, opts: HttpOption<T>) {
  const options = opts as UseFetchOptions<ResOptions<T>>;
  // options.lazy = options.lazy ?? true;

  const { baseUrl = "" } = useRuntimeConfig().public;

  return useFetch<ResOptions<T>>(url, {
    // Request interception
    onRequest({ request, options }) {
      // Set the base URL
      options.baseURL = baseUrl as string;
      options.headers = new Headers(options.headers);
    },
    // Response interception
    onResponse({ request, options, response }) {
      const { errNo } = response._data;
      if (errNo !== 0) handleError<T>(response);
    },
    // Error interception
    onResponseError({ request, options, response }) {
      handleError<T>(response);
    },
    credentials: "include",
    timeout: 20 * 1000, // 20s timeout
    retry: 3,
    retryDelay: 300,
    // Merge the options
    ...options,
  }) as AsyncData<PickFrom<T, KeysOf<T>>, FetchError<ResOptions<T>> | null>;
}

export const useHttp = {
  get: <T>(url: UrlType, params?: SearchParameters, option?: HttpOption<T>) => {
    return fetch<T>(url, { method: "get", params, ...option });
  },

  post: <T>(url: UrlType, body?: RequestInit["body"] | Record<string, unknown>, option?: HttpOption<T>) => {
    return fetch<T>(url, { method: "post", body, ...option });
  },

  put: <T>(url: UrlType, body?: RequestInit["body"] | Record<string, unknown>, option?: HttpOption<T>) => {
    return fetch<T>(url, { method: "put", body, ...option });
  },

  delete: <T>(url: UrlType, body?: RequestInit["body"] | Record<string, unknown>, option?: HttpOption<T>) => {
    return fetch<T>(url, { method: "delete", body, ...option });
  },
};
