/* eslint-disable @typescript-eslint/no-explicit-any */
import { createVNode, render } from "vue";
import type { AppContext, ComponentPublicInstance } from "vue";
import MessageBoxConstructor from "@/components/ui/MessageBox/index.vue";

interface MessageBoxInstance extends ComponentPublicInstance {
  show: () => void;
  doClose: () => void;
}

export interface MessageBoxOptions {
  title?: string;
  message?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  showCancelButton?: boolean;
  dangerouslyUseHTMLString?: boolean;
}

const instances = new Map<MessageBoxInstance, { resolve: (value: any) => void; reject: (reason?: any) => void }>();

const getContainer = (): HTMLElement => {
  return document.createElement("div");
};

const showMessageBox = (options: MessageBoxOptions, appContext?: AppContext | null): Promise<string> => {
  return new Promise((resolve, reject) => {
    const container = getContainer();
    document.body.appendChild(container);

    const vnode = createVNode(MessageBoxConstructor, {
      ...options,
      onConfirm: () => {
        cleanup();
        resolve("confirm");
      },
      onCancel: () => {
        cleanup();
        reject("cancel");
      },
      onClose: () => {
        cleanup();
        reject("close");
      },
    });

    vnode.appContext = appContext || null;
    render(vnode, container);

    const vm = vnode.component!.proxy as MessageBoxInstance;

    if (vm && typeof vm.show === "function") {
      vm.show();
    } else {
      console.error("vm.show is not a function");
    }

    instances.set(vm, { resolve, reject });

    const cleanup = () => {
      render(null, container);
      document.body.removeChild(container);
      instances.delete(vm);
    };
  });
};

export const MessageBox = {
  alert(message: string, options: MessageBoxOptions = {}): Promise<string> {
    return showMessageBox({ ...options, message, showCancelButton: false });
  },
  confirm(message: string, options: MessageBoxOptions = {}): Promise<string> {
    return showMessageBox({ ...options, message, showCancelButton: true });
  },
};
