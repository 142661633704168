export function getTzo() {
    const time = new Date()
    const offset = time.getTimezoneOffset() / -60
    const tzo = `${offset > 0 ? '+' : offset < 0 ? '-' : ''}${Math.abs(offset)}`
    return tzo;
}

export function getCookie(key:string) {
    try {
        const strCookies = document?.cookie?.split(';')
        for (let i = 0; i < strCookies.length; i++) {
        const entry = strCookies[i].split('=').map(function (v) {
            return v.trim()
        })
        if (entry[0] === key) return entry[1]
        }
        return ''
    } catch (error) {
        return ''
    }
}

// 辅助函数：设置cookie
export function setCookie(name: string, value: string, days: number = 365) {
    const expires = new Date(Date.now() + days * 24 * 3600 * 1000).toUTCString();
    const d = window.location.hostname.split('.').slice(-2).join('.');
    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/; domain=${d}`;
}

// 辅助函数：删除cookie
export function deleteCookie(name: string) {
    const d = window.location.hostname.split('.').slice(-2).join('.');
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${d}`;
}

  