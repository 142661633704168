import type { ResOptions } from "@/types/http";
import { md5HashWithSalt } from "@/utils/encryption";
import type { UpdateUserInfo } from "@/types/auth";

const COMMON_PARAMS = {
  appId: "kaley",
  os: "pcweb",
};

function toUrlEncoded(data: any) {
  const urlSearchParams = new URLSearchParams();
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key) && ![null, undefined].includes(data[key])) {
      urlSearchParams.append(key, data[key]);
    }
  }
  return urlSearchParams.toString();
}

// 临时 后续走配置
// function extractSubdomain(hostname: string): string {
//   const parts = hostname.split("-");
//   return parts.length > 1 ? parts[1] : "lgspeakai";
// }

// function buildApiUrl(subdomain: string): string {
//   return `https://passport-${subdomain}-kl.suanshubang.com`;
// }

// const apiUrl = buildApiUrl(extractSubdomain(window?.location?.hostname || "lgspeakai"));

export function getFormUrlEncodedHeaders() {
  return {
    "Content-Type": "application/x-www-form-urlencoded",
  };
}

export const authService = {
  async login(email: string, password: string) {
    const runtimeConfig = useRuntimeConfig();
    const apiUrl = runtimeConfig.public.passportApiBaseUrl;

    return await useHttp.post<ResOptions<any>>(
      `${apiUrl}/session/pc/emaillogin`,
      toUrlEncoded({
        email,
        password: password ? md5HashWithSalt(password) : undefined,
        ...COMMON_PARAMS,
      }),
      {
        headers: getFormUrlEncodedHeaders(),
      }
    );
  },
  async sendEmail({ tokenType, email, password, redirectUri }: { tokenType: string; email: string; password?: string; redirectUri: string }) {
    const params = {
      tokenType,
      email,
      password: password ? md5HashWithSalt(password) : undefined,
      redirect_uri: redirectUri,
      ...COMMON_PARAMS,
    };

    const runtimeConfig = useRuntimeConfig();
    const apiUrl = runtimeConfig.public.passportApiBaseUrl;

    return await useHttp.post<ResOptions<any>>(`${apiUrl}/session/pc/sendemail`, toUrlEncoded(params), {
      headers: getFormUrlEncodedHeaders(),
    });
  },

  async authorize(oauthType: string, redirectUri: string) {
    const runtimeConfig = useRuntimeConfig();
    const apiUrl = runtimeConfig.public.passportApiBaseUrl;
    return await useHttp.post<ResOptions<any>>(
      `${apiUrl}/session/pc/authorize`,
      toUrlEncoded({
        oauthType,
        state: redirectUri,
        ...COMMON_PARAMS,
      }),
      {
        headers: getFormUrlEncodedHeaders(),
      }
    );
  },

  async resetPassword({ code, password }: { code: string; password: string }) {
    const params = {
      code,
      password: password ? md5HashWithSalt(password) : undefined,
      ...COMMON_PARAMS,
    };

    const runtimeConfig = useRuntimeConfig();
    const apiUrl = runtimeConfig.public.passportApiBaseUrl;

    return await useHttp.post<ResOptions<any>>(`${apiUrl}/session/pc/passwordset`, toUrlEncoded(params), {
      headers: getFormUrlEncodedHeaders(),
    });
  },

  // /session/pc/changepassword
  async changePassword({ oldPassword, newPassword }: { oldPassword: string; newPassword: string }) {
    const params = {
      oldpassword: oldPassword ? md5HashWithSalt(oldPassword) : undefined,
      password: newPassword ? md5HashWithSalt(newPassword) : undefined,
      ...COMMON_PARAMS,
    };

    const runtimeConfig = useRuntimeConfig();
    const apiUrl = runtimeConfig.public.passportApiBaseUrl;
    return await useHttp.post<ResOptions<any>>(`${apiUrl}/session/pc/changepassword`, toUrlEncoded(params), {
      headers: getFormUrlEncodedHeaders(),
    });
  },

  async logout() {
    const runtimeConfig = useRuntimeConfig();
    const apiUrl = runtimeConfig.public.passportApiBaseUrl;
    return await useHttp.post<ResOptions<any>>(
      `${apiUrl}/session/pc/logout`,
      toUrlEncoded({
        ...COMMON_PARAMS,
      }),
      {
        headers: getFormUrlEncodedHeaders(),
      }
    );
  },

  async getUserInfo(opts?: { headers?: Record<string, string> }) {
    return await useHttp.get<ResOptions<any>>("/api/user/detail", {
      headers: opts?.headers,
    });
  },

  async updateUserInfo(data: UpdateUserInfo) {
    return await useHttp.post<ResOptions<any>>("/api/user/update", data as Record<string, unknown>);
  },

  async deleteAccount(params: { reason: string; description: string }) {
    return await useHttp.post<ResOptions<any>>("/api/user/delete", params);
  },

  async getUserPlan() {
    return await useHttp.get<ResOptions<any>>("/api/user/plan");
  },
};
