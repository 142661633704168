<template>
  <Transition name="modal">
    <section v-if="visible">
      <div class="fixed bottom-0 left-0 right-0 top-0 z-50 bg-black bg-opacity-50 backdrop-blur-sm"></div>
      <div
        role="dialog"
        class="fixed left-1/2 top-1/2 z-50 max-h-[90%] min-h-12 min-w-40 max-w-full -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-lg border border-[#52525B] bg-[#3F3F3F] p-6"
        v-bind="$attrs"
      >
        <div v-if="title" class="flex items-center justify-center text-xl font-bold">{{ title }}</div>
        <template v-if="message">
          <p v-if="dangerouslyUseHTMLString" class="my-6 text-sm" v-html="message"></p>
          <p v-else class="my-6 text-sm">{{ message }}</p>
        </template>
        <div class="flex justify-end">
          <UButton v-if="showCancelButton" @click="handleAction('cancel')" color="secondary">
            {{ cancelButtonText || "Cancel" }}
          </UButton>
          <UButton class="ml-4" @click="handleAction('confirm')">
            {{ confirmButtonText || "Confirm" }}
          </UButton>
        </div>
      </div>
    </section>
  </Transition>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    title: String,
    message: String,
    showCancelButton: Boolean,
    confirmButtonText: String,
    cancelButtonText: String,
    dangerouslyUseHTMLString: Boolean,
  },
  emits: ["confirm", "cancel", "close"],
  setup(props, { emit }) {
    const visible = ref(false);
    const show = () => {
      visible.value = true;
    };

    const doClose = () => {
      visible.value = false;
      emit("close");
    };

    const handleAction = (action: "confirm" | "cancel") => {
      visible.value = false;
      emit(action);
    };

    return {
      visible,
      show,
      doClose,
      handleAction,
    };
  },
});
</script>
