import { defineStore } from "pinia";

export const useUiStateStore = defineStore("uiState", {
  state: () => ({
    isMobile: false,
    isSidebarVisible: false,
    isSidebarExpanded: true,
  }),

  actions: {
    checkMobile() {
      // TODO 设定移动端的临界点 1024
      this.isMobile = window.innerWidth < 1024;
    },
    setMobile(value: boolean) {
      this.isMobile = value;
    },
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    toggleSidebarExpand() {
      this.isSidebarExpanded = !this.isSidebarExpanded;
    },

    miniSidebar() {
      this.isSidebarExpanded = false;
    },

    showSidebar() {
      this.isSidebarVisible = true;
    },
    hideSidebar() {
      this.isSidebarVisible = false;
    },
  },
});
