// 3000  未登录
// 3001  首次登录,强制填写昵称+性别
// 3002 首次登录,需要选择年龄
// 3003 小于18岁, 封禁状态
// 3004 小于18岁, 封禁状态, 允许重新确认年龄
// 3011 账户不允许访问(已删除等)
// 4000 参数无效
// 4001 参数错误
// 5000 服务错误

export const STATUS_CODE_HANDLERS = {
  // for test
  // 3000: () => {
  //   const authStore = useAuthStore();
  //   authStore.showUserSetupModal("SetupUnderAge");
  // },
  3001: () => {
    const authStore = useAuthStore();
    authStore.showUserSetupModal("SetupNameAndPronoun");
  },
  3002: () => {
    const authStore = useAuthStore();
    authStore.showUserSetupModal("SetupAgeSelection");
  },
  3003: () => {
    const authStore = useAuthStore();
    authStore.showUserSetupModal("SetupUnderAge");
  },
  3004: () => {
    const authStore = useAuthStore();
    authStore.showUserSetupModal("SetupUnderAgeWithChangeAge");
  },
};

export const useStatusCodeHandler = () => {
  const handleStatusCode = (statusCode: number) => {
    const handler = STATUS_CODE_HANDLERS[statusCode as keyof typeof STATUS_CODE_HANDLERS];
    if (handler) {
      console.log("[useStatusCodeHandler]", statusCode);
      handler();
    } else {
      console.warn(` ${statusCode} `);
    }
  };
  return {
    handleStatusCode,
  };
};
