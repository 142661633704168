import { authService } from "~/services/auth";
import type { UpdateUserInfo } from "~/types/auth";
// import { USER_SETUP_CODES_VIEWS } from "~/config";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
    // 接口状态码
    userInfoCode: -1,

    authContainerViewMode: "modal",

    // 登录弹窗
    isAuthViewContainerOpen: false,
    // 登录弹窗初始视图
    authContainerView: "SignInForm",
    // 首次登录弹窗
    isSetupModalVisible: false,
    // 首次登录弹窗初始视图
    setupModalView: "SetupNameAndPronoun",

    // 注册弹窗邮箱 展现使用
    signupInputEmail: "",
    signupInputPassword: "",
  }),

  getters: {
    isLoggedInWithUserInfo: (state) => !!state.user && state.userInfoCode === 0,
    isLoggedIn: (state) => !!state.user,
    isPageAuthContainerViewMode: (state) => state.authContainerViewMode === "page",
  },

  actions: {
    setAuthContainerViewMode(mode: "modal" | "page") {
      this.authContainerViewMode = mode;
    },

    updateSignupInputInfo(email: string, password: string) {
      this.signupInputEmail = email;
      this.signupInputPassword = password;
    },
    showAuthContainerView(view: string = "SignInForm") {
      this.authContainerView = view;
      this.isAuthViewContainerOpen = true;
    },
    hideAuthViewContainer() {
      this.isAuthViewContainerOpen = false;
    },

    showUserSetupModal(view: string = "SetupNameAndPronoun") {
      this.setupModalView = view;
      this.isSetupModalVisible = true;
    },
    hideUserSetupModal() {
      this.isSetupModalVisible = false;
    },
    async login(email: string, password: string) {
      try {
        const { data, error } = await authService.login(email, password);
        if (error.value) {
          throw error.value;
        }

        return data.value;
      } catch (error) {
        console.error("[Auth] Failed to login:", error);
        return false;
      }
    },
    async getUserInfo(opts?: { headers?: Record<string, string> }) {
      try {
        const { data, error } = await authService.getUserInfo(opts);
        if (error.value) {
          throw error.value;
        }

        this.user = Object.keys(data.value?.data).length > 0 ? data.value.data : null;

        this.userInfoCode = data.value.errNo;
      } catch (error) {
        console.error("[Auth] Failed to get user info:", error);
      }
    },
    async authorizeByThirdParty(oauthType: string) {
      try {
        const redirectUri = `${window.location.origin}/users/redirect`;
        const { data, error } = await authService.authorize(oauthType, redirectUri);

        if (data.value.errNo === 0) {
          window.location.href = data.value.data.authURL;
        }

        if (error.value) {
          throw error.value;
        }
      } catch (error) {
        console.error("[Auth] Failed to authorize:", error);
      }
    },

    async sendEmailBySignup(email: string, password: string) {
      try {
        const redirectUri = `${window.location.origin}/users/redirect`;
        const { data, error } = await authService.sendEmail({ tokenType: "600001", email, password, redirectUri });

        return data.value;
      } catch (error) {
        console.error("[Auth] Failed to send email:", error);
      }
    },
    async sendEmailByForgotPassword(email: string) {
      try {
        const redirectUri = `${window.location.origin}/users/password/reset`;
        const { data, error } = await authService.sendEmail({ tokenType: "600002", email, redirectUri });

        if (error.value) {
          throw error.value;
        }

        return data.value;
      } catch (error) {
        console.error("[Auth] Failed to send email:", error);
      }
    },

    async changePassword(params: { oldPassword: string; newPassword: string }) {
      try {
        const { data, error } = await authService.changePassword(params);
        return data.value;
      } catch (error) {
        console.error("[Auth] Failed to change password:", error);
      }
    },

    async resetPassword(params: { code: string; password: string }) {
      try {
        const { data, error } = await authService.resetPassword(params);
        return data.value;
      } catch (error) {
        console.error("[Auth] Failed to reset password:", error);
      }
    },

    async logout() {
      try {
        const { data, error } = await authService.logout();
        this.user = null;
        this.userInfoCode = -1;
        return data.value;
      } catch (error) {
        console.error("[Auth] Failed to logout:", error);
      }
    },

    // delete user
    async deleteAccount(params: { reason: string; description: string }) {
      try {
        const { data, error } = await authService.deleteAccount(params);
        return data.value;
      } catch (error) {
        console.error("[Auth] Failed to delete user:", error);
      }
    },
    async updateUserInfo(params: UpdateUserInfo) {
      try {
        const { data, error } = await authService.updateUserInfo(params);
        return data.value;
      } catch (error) {
        console.error("[Auth] Failed to update user info:", error);
      }
    },
    async getUserPlan() {
      try {
        const { data, error } = await authService.getUserPlan();
        return data.value;
      } catch (error) {
        console.error("[Auth] Failed to get user plan:", error);
      }
    },
  },
});
