/* eslint-disable */
// 用户行为数据采集
import getCuid from "@/common/clientId";
import { getTzo, getCookie } from "@/common/tools/index";
// import { isInStandaloneMode, checkOKXApp } from '@/common/platform'
import browser, {s, getSysPlat} from "@/common/tools/browser";

class UserBehavior {
  private initOptions: any
  url: string
  uid: string
  constructor(options: any = {}) {
    this.initOptions = { ...options }
    this.uid = ''
    // this.url = 'https://autotrack.kaley.ai/log/kaley_h5_test'
    this.url = 'https://autotrack.kaley.ai/log/kaley_h5'

  }

  // 上报事件
  sendEvent(name : string, data = {}) {
    // 默认携带客户端上报事件，使用ISO格式
    const time = new Date()
    const clientTime = time.toISOString()
    try {
      const dataObj = { 
        mc: browser.os(),
        av: browser.kernel(),
        fr: getSysPlat(),
        s: s,
        i: getCuid(),

        name,
        ga: getCookie('_ga'),
        gid: getCookie('_gid'), 
        uid: this.uid,
        clientTime, 
        tzo: getTzo(), 
        source: 'main', 
        extData: JSON.stringify(data)
      }
      return this.sendLog({
        url: this.url,
        data: dataObj
      })
    } catch (error) {
      return false
    }
  }

  setUid(uid: string) {
    this.uid = uid
  }

  sendGaEvent(name : string, data = {}) {
    try {
      // console.log('======= ga send', name, data, (window as any).gtag);
      (window as any).gtag && (window as any).gtag('event', 'K_' + name, data)
    } catch (error) {
      console.warn('gaEvent ', error)
    }
  }

  private sendLog(option: any): Promise<any> {
    const url = option.url
    const data = option.data
    return new Promise<void>((resolve, reject) => {
      let querys = []
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const value = data[key]
          if (typeof value !== 'undefined' && value !== '') {
            querys.push(`${key}=${encodeURIComponent(value)}`)
          }
        }
      }
      // 由于某些情况下会出现一批点位参数完全一致的展现事件的上报(例如下拉加载分页)
      // 但是浏览器会对一段时间内请求参数完全一致的请求忽略
      // 下面的操作是为了保证每次的t不一样
      // 也可以通过加一个随机的参数来保证不一样
      // 但是这个随机的参数会上报入库
      const start = +new Date()
      while (+new Date() - start < 1) {}
      try {
        const i = new Image()
        i.src = `${url}?${querys.join('&')}`
        resolve()
      } catch (error) {
        reject(error)
      }
      
    })
  }

}
export default new UserBehavior()

// export default defineNuxtPlugin(() => {
//   return {
//     provide: {
//       behaviorEvent: new UserBehavior()
//     },
//   };
// });
