import revive_payload_client_evdD8tw5S3 from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._b3haa3vjmnj36gbnay6sarzubi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_dhgFyhwlIJ from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._b3haa3vjmnj36gbnay6sarzubi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZyngiIdmN8 from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._b3haa3vjmnj36gbnay6sarzubi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_VMDgLwukTM from "/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.4_rollup@4.21.1_vite@5.4.2_@types+node@18.19.46_terser@5_oyv7alvle6zziklfyyrqouse6m/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_aHz2JjYQJR from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._b3haa3vjmnj36gbnay6sarzubi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_5ZJgOqzCYO from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._b3haa3vjmnj36gbnay6sarzubi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_uuYAz7TCNM from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._b3haa3vjmnj36gbnay6sarzubi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_3Ry1xhpN94 from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._b3haa3vjmnj36gbnay6sarzubi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_0ooSBU89Bu from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.4_rollup@4.21.1_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_wmXIgmi1Vm from "/app/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@18.19.46_eslint@9.9.1_jiti@1.21.6__ioredis@5.4._b3haa3vjmnj36gbnay6sarzubi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_hGa5ZEKnMR from "/app/node_modules/.pnpm/@sentry+nuxt@8.31.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._tezh7xvvc6gh3bben6xs2fh7aa/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/app/.nuxt/sentry-client-config.mjs";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_FqxQTUkwgH from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.4_rollup@4.21.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_rJovq5VJ7d from "/app/node_modules/.pnpm/@nuxt+icon@1.5.0_magicast@0.3.4_rollup@4.21.1_vite@5.4.2_@types+node@18.19.46_terser@5.31.6___dzelaei5gpp4glrilydo6bh7v4/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import behaviorEvent_07UXq7y4Hx from "/app/plugins/behaviorEvent.ts";
import infinite_scroll_p7JqSB3ZG6 from "/app/plugins/infinite-scroll.ts";
import messageBox_wzyyHMUbt8 from "/app/plugins/messageBox.ts";
import mock_user_session_5J9rgKuV1O from "/app/plugins/mock-user-session.ts";
export default [
  revive_payload_client_evdD8tw5S3,
  unhead_dhgFyhwlIJ,
  router_ZyngiIdmN8,
  _0_siteConfig_VMDgLwukTM,
  payload_client_aHz2JjYQJR,
  navigation_repaint_client_5ZJgOqzCYO,
  check_outdated_build_client_uuYAz7TCNM,
  chunk_reload_client_3Ry1xhpN94,
  plugin_vue3_0ooSBU89Bu,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wmXIgmi1Vm,
  sentry_client_hGa5ZEKnMR,
  sentry_client_config_o34nk2sJbg,
  plugin_wy0B721ODc,
  plugin_client_FqxQTUkwgH,
  plugin_rJovq5VJ7d,
  behaviorEvent_07UXq7y4Hx,
  infinite_scroll_p7JqSB3ZG6,
  messageBox_wzyyHMUbt8,
  mock_user_session_5J9rgKuV1O
]