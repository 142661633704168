import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as indexTdNG1mTwcoMeta } from "/app/pages/ai-generator/index.vue?macro=true";
import { default as quick5PpXCf6yZCMeta } from "/app/pages/ai-generator/quick.vue?macro=true";
import { default as _91id_93MPhw7XwoTYMeta } from "/app/pages/ai-girlfriend/[id].vue?macro=true";
import { default as _91id_934XFRWzDPlxMeta } from "/app/pages/ai-girlfriend/chat/[id].vue?macro=true";
import { default as indexhwWr6uQSBDMeta } from "/app/pages/ai-girlfriend/index.vue?macro=true";
import { default as _91id_932Nroxoa7WyMeta } from "/app/pages/ai-girlfriend/profile/[id].vue?macro=true";
import { default as _91_91id_93_937rfQj5kyqkMeta } from "/app/pages/ai-image-generator/[[id]].vue?macro=true";
import { default as index8yMpwGpDkEMeta } from "/app/pages/ai-image/index.vue?macro=true";
import { default as indexUjKfmP3izhMeta } from "/app/pages/characters/index.vue?macro=true";
import { default as newPRItE8hJFIMeta } from "/app/pages/characters/new.vue?macro=true";
import { default as indexZcIuYx5J9sMeta } from "/app/pages/demo/index.vue?macro=true";
import { default as indexgEgsnMNAWsMeta } from "/app/pages/explore/index.vue?macro=true";
import { default as index3J5r3Sf7qAMeta } from "/app/pages/index/index.vue?macro=true";
import { default as index11eXGkSJBcMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as indexomvABG9sEsMeta } from "/app/pages/test-auth/index.vue?macro=true";
import { default as redirecttKxM3EqNCvMeta } from "/app/pages/test-auth/redirect.vue?macro=true";
import { default as reset0kcBLYTpIFMeta } from "/app/pages/users/password/reset.vue?macro=true";
import { default as redirectWwFup357ILMeta } from "/app/pages/users/redirect.vue?macro=true";
import { default as indexsXkc811B1WMeta } from "/app/pages/users/setup/index.vue?macro=true";
import { default as sign_inpfi8yVhm0TMeta } from "/app/pages/users/sign_in.vue?macro=true";
import { default as sign_up2x1DkPK1diMeta } from "/app/pages/users/sign_up.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutu8Sb32ihwPMeta || {},
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "ai-generator",
    path: "/ai-generator",
    meta: indexTdNG1mTwcoMeta || {},
    component: () => import("/app/pages/ai-generator/index.vue").then(m => m.default || m)
  },
  {
    name: "ai-generator-quick",
    path: "/ai-generator/quick",
    meta: quick5PpXCf6yZCMeta || {},
    component: () => import("/app/pages/ai-generator/quick.vue").then(m => m.default || m)
  },
  {
    name: "ai-girlfriend-id",
    path: "/ai-girlfriend/:id()",
    meta: _91id_93MPhw7XwoTYMeta || {},
    component: () => import("/app/pages/ai-girlfriend/[id].vue").then(m => m.default || m)
  },
  {
    name: "ai-girlfriend-chat-id",
    path: "/ai-girlfriend/chat/:id()",
    meta: _91id_934XFRWzDPlxMeta || {},
    component: () => import("/app/pages/ai-girlfriend/chat/[id].vue").then(m => m.default || m)
  },
  {
    name: "ai-girlfriend",
    path: "/ai-girlfriend",
    meta: indexhwWr6uQSBDMeta || {},
    component: () => import("/app/pages/ai-girlfriend/index.vue").then(m => m.default || m)
  },
  {
    name: "ai-girlfriend-profile-id",
    path: "/ai-girlfriend/profile/:id()",
    meta: _91id_932Nroxoa7WyMeta || {},
    component: () => import("/app/pages/ai-girlfriend/profile/[id].vue").then(m => m.default || m)
  },
  {
    name: "ai-image-generator-id",
    path: "/ai-image-generator/:id?",
    meta: _91_91id_93_937rfQj5kyqkMeta || {},
    component: () => import("/app/pages/ai-image-generator/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "ai-image",
    path: "/ai-image",
    meta: index8yMpwGpDkEMeta || {},
    component: () => import("/app/pages/ai-image/index.vue").then(m => m.default || m)
  },
  {
    name: "characters",
    path: "/characters",
    meta: indexUjKfmP3izhMeta || {},
    component: () => import("/app/pages/characters/index.vue").then(m => m.default || m)
  },
  {
    name: "characters-new",
    path: "/characters/new",
    component: () => import("/app/pages/characters/new.vue").then(m => m.default || m)
  },
  {
    name: "demo",
    path: "/demo",
    meta: indexZcIuYx5J9sMeta || {},
    component: () => import("/app/pages/demo/index.vue").then(m => m.default || m)
  },
  {
    name: "explore",
    path: "/explore",
    component: () => import("/app/pages/explore/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: index11eXGkSJBcMeta || {},
    component: () => import("/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "test-auth",
    path: "/test-auth",
    component: () => import("/app/pages/test-auth/index.vue").then(m => m.default || m)
  },
  {
    name: "test-auth-redirect",
    path: "/test-auth/redirect",
    component: () => import("/app/pages/test-auth/redirect.vue").then(m => m.default || m)
  },
  {
    name: "users-password-reset",
    path: "/users/password/reset",
    component: () => import("/app/pages/users/password/reset.vue").then(m => m.default || m)
  },
  {
    name: "users-redirect",
    path: "/users/redirect",
    component: () => import("/app/pages/users/redirect.vue").then(m => m.default || m)
  },
  {
    name: "users-setup",
    path: "/users/setup",
    component: () => import("/app/pages/users/setup/index.vue").then(m => m.default || m)
  },
  {
    name: "users-sign_in",
    path: "/users/sign_in",
    meta: sign_inpfi8yVhm0TMeta || {},
    component: () => import("/app/pages/users/sign_in.vue").then(m => m.default || m)
  },
  {
    name: "users-sign_up",
    path: "/users/sign_up",
    meta: sign_up2x1DkPK1diMeta || {},
    component: () => import("/app/pages/users/sign_up.vue").then(m => m.default || m)
  }
]