<template>
  <component :is="component" :name="resolvedName" :style="computedStyle" class="icon" :class="computedClass" aria-hidden="true">
    <use v-if="isSvgMode && !isIconComponent" :xlink:href="`#icon-${resolvedName}`" />
  </component>
</template>

<script setup lang="ts">
import { cn } from "@/utils";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  mode: {
    type: String as PropType<"svg" | "css">,
    default: null,
  },
  size: {
    type: [Number, String],
    default: null,
  },
  color: {
    type: String,
    default: "currentColor",
  },
  rotate: {
    type: Number,
    default: 0,
  },
  className: {
    type: String,
    default: "",
  },
});

const runtimeOptions = useAppConfig().icon || {};

const resolvedName = computed(() => props.name || "default-icon");

const isSvgMode = computed(() => (props.mode || runtimeOptions.mode) === "svg");

// 兼容已有的 Icon(iconify)
const isIconComponent = computed(() => resolvedName.value.includes(":"));

const component = computed(() => (isIconComponent.value ? "Icon" : isSvgMode.value ? "svg" : "span"));

const computedStyle = computed(() => {
  const size = props.size || runtimeOptions.size;
  const sizeStyle = size ? { fontSize: Number.isNaN(+size) ? size : `${size}px` } : null;
  return {
    ...sizeStyle,
    fill: props.color,
    transform: props.rotate ? `rotate(${props.rotate}deg)` : "",
  };
});

const computedClass = computed(() => {
  const baseClass = isSvgMode.value ? "inline-block align-middle" : "";
  return cn(baseClass, props.className);
});
</script>

<style scoped>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

svg {
  display: inline-block;
  vertical-align: middle;
  transition: transform 0.2s ease-in-out;
}
span {
  display: inline-block;
  vertical-align: middle;
}
</style>
