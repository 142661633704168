import { nanoid } from "nanoid";

export default defineNuxtPlugin((nuxtApp) => {
  const { hiddenPassportFeature } = useRuntimeConfig();

  if (hiddenPassportFeature) {
    // 测试阶段 模拟用户 session
    const cookies = useCookie("session", {
      maxAge: 60 * 60 * 24 * 100, // 100 days
    });

    if (!cookies.value) {
      cookies.value = nanoid();
    }
  }
});
