/**
 * @fileoverview 浏览器类型判断
 *
 * @method chrome()                // 方法：检测浏览器是否为 Chrome.
 *   @param No                     // 参数：无。
 *   @return {Number|undefined}    // 返回：Chrome 版本号(非 Chrome 浏览器返回 undefined)。
 *
 * @method firefox()               // 方法：检测浏览器是否为 Firefox.
 *   @param No                     // 参数：无。
 *   @return {Number|undefined}    // 返回：Firefox 版本号(非 Firefox 浏览器返回 undefined)。
 *
 * @method ie()                    // 方法：检测浏览器是否为 IE.
 *   @param No                     // 参数：无。
 *   @return {Number|undefined}    // 返回：IE 版本号(非 IE 浏览器返回 undefined)。
 *
 * @method safari()                // 方法：检测浏览器是否为 Safari.
 *   @param No                     // 参数：无。
 *   @return {Number|undefined}    // 返回：Safari 版本号(非 Safari 浏览器返回 undefined)。
 *
 * @method isStandard()    // 方法：检测文档是否启用“标准模式”渲染。
 *   @param No             // 参数：无。
 *   @return {Boolean}     // 返回：检测结果。
 *
 * @method isGecko()      // 方法：检测浏览器的排版引擎是否为 gecko.
 *   @param No            // 参数：无。
 *   @return {Boolean}    // 返回：检测结果。
 *
 * @method isWebkit()     // 方法：检测浏览器的排版引擎是否为 webkit.
 *   @param No            // 参数：无。
 *   @return {Boolean}    // 返回：检测结果。
 *
 * @method os()          // 方法：获取用户操作系统类型。
 *   @param No           // 参数：无。
 *   @return {String}    // 返回：描述用户操作系统类型的字符串。
 *
 * @method kernel()      // 方法：获取用户浏览器内核类型。
 *   @param No           // 参数：无。
 *   @return {String}    // 返回：描述用户浏览器内核类型的字符串。
 *
 * @description    // 附加说明。
 *   1) 本方法用于检测用户操作系统、浏览器类型及渲染模式、排版引擎等；相应的浏览器检测方法返回检测到的浏览器版本号；
 *      不匹配的浏览器类型字段值为 undefined (请参阅示例)。
 *
 * @example    // 典型的调用示例。
 * // 本示例为 Windows 10 64bit 环境 Chrome 49.0.2623.110 m (64-bit) 版本下，fe-handbook(2016-04-08)的检测结果。
 * var browser = require('common:widget/util/browser.js');
 *
 * browser.chrome();        // 49
 *  browser.firefox();       // undefined
 *  browser.ie();            // undefined
 *  browser.safari();        // undefined
 *  browser.isGecko();       // false
 *  browser.isStandard();    // true
 *  browser.isWebkit();      // true
 *  browser.os();            // windows 10_64bit
 *  browser.kernel();        // chrome_49
 */
let ua = ''
try {
  ua = navigator.userAgent
} catch (e) {
  ua = ''
}
// const HREF = window.location.href
const SCREEN = (window && window.screen) || ''

export default {
  chrome() {
    return /\b(?:Chrome|CriOS)\/(\d+)/i.test(ua) ? +RegExp.$1 : undefined
  },
  firefox() {
    return /\bFirefox\/(\d+)/i.test(ua) ? +RegExp.$1 : undefined
  },
  ie() {
    return /\b(?:MSIE |rv:|Edge\/)(\d+)/i.test(ua) && !this.firefox()
      ? (document as any).documentMode || +RegExp.$1
      : undefined // Firefox 某些版本的 ua 中含有和 IE 11 一样的 'rv:' 字段。
  },
  edge() {
    return /\bEdge\/(\d+)/i.test(ua) ? (document as any).documentMode || +RegExp.$1 : undefined
  },
  safari() {
    return /\bSafari\/?(\d+)?/i.test(ua) && !/chrome/i.test(ua)
      ? +(RegExp.$1 || RegExp.$2)
      : undefined
  },
  isStandard() {
    return document.compatMode == 'CSS1Compat'
  },
  isGecko() {
    return /gecko/i.test(ua) && !/like gecko/i.test(ua)
  },
  isWebkit() {
    return /webkit/i.test(ua)
  },
  os() {
    let os = 'other'

    if (/\bMac/i.test(ua)) {
      if (/iPhone/i.test(ua)) {
        os = `os_${
          /iPhone OS (\d+(?:_\d+(_\d+)?)?)/i.test(ua) ? RegExp.$1.replace(/_/g, '.') : 'unknown'
        }`
      } else if (/iPad/i.test(ua)) {
        os = `os_${
          /iPad.*OS (\d+(?:_\d+(_\d+)?)?)/i.test(ua) ? RegExp.$1.replace(/_/g, '.') : 'unknown'
        }`
      } else {
        os = `os_${
          /Mac OS X (\d+(?:_\d+(_\d+)?)?)/i.test(ua) ? RegExp.$1.replace(/_/g, '.') : 'unknown'
        }`
      }
    } else if (/Android/i.test(ua)) {
      os = `os_${/Android (\d+(?:\.\d+(.\d+)?)?)/i.test(ua) ? RegExp.$1 : 'unknown'}`
    } else if (/\bWindows/i.test(ua)) {
      os = `os_${/Windows NT (\d+)/i.test(ua) ? RegExp.$1 : 'unknown'}_${
        /win64|x64|wow64/i.test(ua) ? '64' : '32'
      }bit`
    }

    return os
  },
  kernel() {
    let browser = 'other'

    if (this.edge()) {
      browser = `edge_${this.edge()}`
    } else if (this.ie()) {
      browser = `ie_${this.ie()}`
    } else if (this.chrome()) {
      browser = `chrome_${this.chrome()}`
    } else if (this.safari()) {
      browser = `safari_${this.safari()}`
    } else if (this.firefox()) {
      browser = `firefox_${this.firefox()}`
    }

    return browser
  }
}

// 获取系统平台(iphone/ipad/android/windows/other)
export function getSysPlat() {
  // var isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  let isiPhone = ua.indexOf('iPhone') > 0 // iPhone终端
  let isiPad = ua.indexOf('iPad') > 0 // iPad终端
  let isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1 // android终端
  let isWindows = ua.indexOf('Windows') > 0
  let isMac = ua.indexOf('Mac') > 0
  if (isAndroid) {
    return 'android'
  } else if (isiPad) {
    return 'ipad'
  } else if (isiPhone) {
    return 'iphone'
  } else if (isWindows) {
    return 'windows'
  } else if (isMac) {
    return 'mac'
  }
  return 'others'
}

/**
 * 网络状态
 */
export const networkType = (() => {
  const ma = ua.match(/NetType\/\w+/)
  let networkStr = 'NetType/other'
  if (ma) {
    networkStr = ma[0]
  }
  networkStr = networkStr.toLowerCase().replace('nettype/', '')
  let networkType
  switch (networkStr) {
    case 'wifi':
      networkType = 'wifi'
      break
    case '5g':
      networkType = '5g'
      break
    case '4g':
      networkType = '4g'
      break
    case '3g':
      networkType = '3g'
      break
    case '3gnet':
      networkType = '3g'
      break
    case '2g':
      networkType = '2g'
      break
    default:
      networkType = 'others'
  }
  return networkType
})()

// 分辨率
export const s = `${(SCREEN && SCREEN.width) || 0}*${(SCREEN && SCREEN.height) || 0}`

// 浏览器状态
export function browserStatus() {
  // 各种浏览器兼容
  let hidden, state: any, visibilityChange
  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
    state = 'visibilityState'
  } else if (typeof (document as any).mozHidden !== 'undefined') {
    hidden = 'mozHidden'
    visibilityChange = 'mozvisibilitychange'
    state = 'mozVisibilityState'
  } else if (typeof (document as any).msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
    state = 'msVisibilityState'
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
    state = 'webkitVisibilityState'
  }
  return { hidden, visibilityChange, state }
}
