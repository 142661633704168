import ClientJS from "./client.base";
import { bech32 } from "./bech32";
import { Buffer } from "buffer";
import { getTzo, setCookie } from "../tools/index";

function _getCuid() {
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();
  const words = bech32.toWords(Buffer.from(fingerprint, "utf8"));
  const cuid = bech32.encode("kaley", words);
  return cuid + getTzo();
}

export default function getCuid() {
  if (import.meta.server) {
    return "";
  }
  try {
    const str = localStorage?.getItem('x-client-id');
    let cuid = ''
    if (str) {
      cuid = str;
    } else {
      const _cuid = _getCuid();
      localStorage?.setItem("x-client-id", _cuid);
      cuid = _cuid;
    }
    setCookie('cuid', cuid)
    return cuid;
  } catch (error) {
    return "";
  }
}
