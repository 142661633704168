// 使用 meta 进行配置
// - isStatic 页面不进行用户信息请求、处理
// - requiresAuth 页面进行用户信息请求、处理
// - 其他（进行用户信息请求、不处理; 刷新页面情况）

// 静态页面
const STATIC_PAGES = ["/about"];

export default defineNuxtRouteMiddleware(async (to, from) => {
  const authStore = useAuthStore();

  // 静态页面
  const isStaticPage = STATIC_PAGES.includes(to.path) || to.meta.isStatic || false;

  // 如果是静态页面，则跳过任何登录和权限逻辑
  if (isStaticPage) {
    return;
  }

  // 需要登录的页面
  const requiresAuth = to.meta.requiresAuth;

  // 已有用户信息
  if (authStore.isLoggedInWithUserInfo) {
    return;
  }

  // 用户信息获取（登录检测）
  if (import.meta.server) {
    const nuxtApp = useNuxtApp();
    const { ssrContext } = nuxtApp;
    if (ssrContext) {
      const reqHeaders = ssrContext.event.node.req.headers;
      await authStore.getUserInfo({ headers: reqHeaders as Record<string, string> });
    }
  } else {
    await authStore.getUserInfo();
  }

  if (requiresAuth) {
    if (!authStore.isLoggedIn) {
      // 未登录用户处理
      if (to.path === from.path) {
        // 已在受限页面，但用户未登录，跳转首页
        return navigateTo("/");
      } else {
        // 尝试进入受限页面，但未登录，弹出登录窗口并阻止跳转
        authStore.showAuthContainerView();
        return abortNavigation();
      }
    }
  }
});
